/* Import Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import Google Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Hide ApexCharts menu items */
.apexcharts-menu-item.exportSVG {
    display: none;
}

.apexcharts-menu-item.exportCSV {
    display: none;
}

/* Tooltip CSS */
.tooltip {
    position: absolute; /* Position the tooltip relative to the parent */
    top: calc(100% + 0.5rem); /* Position the tooltip below the parent with a small gap */
    left: 50%; /* Position the tooltip horizontally centered */
    transform: translateX(-50%); /* Center the tooltip horizontally */
    background-color: rgba(102, 96, 96, 0.75); /* Background color of the tooltip */
    color: #0f0e0e; /* Text color of the tooltip */
    padding: 0.5rem; /* Padding around the tooltip content */
    border-radius: 0.25rem; /* Border radius of the tooltip */
    white-space: nowrap; /* Prevent line breaks in the tooltip */
    z-index: 999; /* Ensure the tooltip appears above other elements */
    display: none; /* Initially hide the tooltip */
}

.tooltip.active {
    display: block; /* Display the tooltip when active */
}

.dropdown-on-top {
    z-index: 1000;
    position: relative; /* Make sure the position is relative or absolute */
}
